import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
 
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 
@Injectable()
export class DataService {

    // private readonly URL = 'http://localhost:3000';
    
    // private readonly URL = 'https://staging.stagingsdei.com:8081';

    private readonly URL = 'https://allsmartvideo.net:8001';

  constructor(private httpClient: HttpClient) { }

    createMeeting(meetingTitle:String): Observable<any> {
      // console.log('CALLING---' + this.URL + '/create-meeting API');
      return this.httpClient.get(this.URL + '/create-meeting?title='+meetingTitle);
    }

    createAttendee(meetingId:String, attendeeName:String): Observable<any> {
      // console.log('CALLING---' + this.URL + '/create-attendee API');
      return this.httpClient.get(this.URL +  '/create-attendee?meetingId='+meetingId+'&name='+attendeeName);
    }

    getMeetingAttendees(meetingId:String) : Observable<any> {
      return this.httpClient.get(this.URL +  '/get-attendees?meetingId='+meetingId);
    }

    //https://nearest-media-region.l.chime.aws


}