
<section class="home-banner">
  <div class="left-banner">
    <div class="meeting-form">
      <h1>Let's Start a
          <span>Meet<samp>ing</samp></span></h1>
      <p><strong> Connect</strong> with <strong> anyone, anywhere</strong> <br />
          Do meetings online</p>
          <a routerLink="/meeting-room" routerLinkActive="active" class="btn-submit">Start Meeting Now
              <span>
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1.58478 11.0582C1.58478 16.5233 6.06193 20.9536 11.5848 20.9537C17.1076 20.9537 21.5848 16.5233 21.5848 11.0582C21.5848 5.59317 17.1076 1.16284 11.5848 1.16284C6.06193 1.16284 1.58478 5.59316 1.58478 11.0582Z"
                          stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.5848 15.0164L15.5848 11.0583L11.5848 7.1001" stroke="white"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M7.58478 11.0582L15.5848 11.0582" stroke="white" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </span>
          </a	>
  </div>
  </div>
  <div class="right-banner">
    <div class="banner-img">
      <img src="../../assets/images/home-banner.png" class="img-fluid" alt="banner">
    </div>
  </div>
</section>


<section class="video-solution">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 align-self-center">
        <div class="software-outer">
          <img src="../../assets/images/multicall.png" class="img-fluid" alt="image">
        </div>
      </div>
      <div class="col-sm-6 align-self-center">
        <div class="software-content">
          <h2><strong> Video </strong>solution by<strong> <span> smartData</span></strong><span class="black">,</span><br />
              conferencing, one to
              one call</h2>
              <p>One to one and one to many audio/video chat
                  Our video consulting platform provides real-time audio/video streaming of data from one device to another using native WebRTC libraries. Hosted on a scalable AWS infrastructure the video platform offers a range of consultation solutions – text, audio and video chat. It supports all the latest desktop and mobile web browsers, a user-friendly and secure platform to connect over video calls.
                  
                  The frontend video streaming component is built on Angular JS so that it can be integrated with any modern web application easily. It can be used as a standalone platform or integrated with existing systems for more effective calls.
                  The solution also uses intelligent bandwidth management to adjust audio/video and data transfer rates based on internet connection bandwidth of participants for better user experience. The solution is also capable of making customized settings based on different parameters for auto lock meeting rooms, password-protected rooms and screen sharing capabilities.
                  </p>

              
      </div>
      </div>
    </div>
  </div>
</section>


<section class="feature-sec">
  <div class="container">
    <div class="col-md-6 offset-md-3 text-center">
      <h2><strong> Unlimited </strong>Features, Enhanced<br />
          <strong><span> Productivity</span></strong></h2>
          <p>The applicability is proven in many domains, major ones being healthcare for doctor-patient consultation, e-learning, career counselling, legal and more.
              Main features are:
              </p>
              <div>
                  <ul class="text-left circle-list">
                      <li>Admin module</li>
                      <li>Chat module – text, audio and video (one to one and one to many)</li>
                      <li>Direct consulting</li>
                      <li>Connect with multiple participants</li>
                      <li>Waiting room</li>
                      <li>Pricing module</li>
                      <li>Quality video and audio chat</li>
                      <li>Supports all latest desktop/ mobile web browsers</li>
                      <li>File transfer support</li>
                      <li>Easily customizable UI/UX</li>
                  </ul>
              </div>
  </div>
  </div>
</section>
<section class="feature-sec">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
             
              <div class="learge-img">
                  <img src="../../assets/images/meeting.png" class="img-fluid">
              </div>
          </div>
      </div>
      
  </div>
</section>
<section class="testimonial-section">
  <div class="row align-items-center m-0">
    <div class="col-lg-6">
      <div class="testi-text">
      <h2>Check <span>testimonials</span> for our satisfied <span>clients</span></h2>
      <p>That ummm I'm telling Why say bleeder smashing lemon squeezy tosser, cheesed off dropped.!</p>
    </div>
    </div>
    <div class="col-lg-6">
      <carousel
      [cellWidth]="'100%'"
      >
        <div class="carousel-cell">
          <div class="inner-content">
          <img src="../../assets/images/kurt.jpg">
          <p>
            We have been working with smartData since 2009 implementing a large practice management solution in healthcare. It has been great working with the team. Their know-how in healthcare has led to many useful discussions. We look forward to working with them in the coming years.
          </p>
          <div class="client-name">
            <p>Kurt</p>
            <p>CTO</p>
        </div>
          </div>
        </div>
        <div class="carousel-cell">
          <div class="inner-content">
          <img src="../../assets/images/john.jpg">
          <p style="font-size: small;">
            smartData has been our offshore software development partner for 5 years. Sometimes offshore gets a bad reputation and one needs to be careful selecting a company. For us, we have had a very long relationship with smartData and they have always stood to the time and quality. It’s not just software development in healthcare, fintech, and different industries; they have subject matter expertise and understanding of fundamentals you are probably looking to develop.
        </p>
        <div class="client-name">
            <p>John</p>
            <p>Head of Technology</p>
        </div>
        </div>
        </div>
        <div class="carousel-cell">
          <div class="inner-content">
          <img src="../../assets/images/jay.jpeg">
          <p style="font-size: small;">
            We are software company focusing on the healthcare sector. smartData and we have been in a partnership for 3 years now and they have been great partners. We first hired them to stabilize our platform but as we moved along they have bild our entire tech stack. They have had a great consistency with our team and the range of technical expertise and a variety of tech skills like AI, ML, and NLP has been a plus. We look forward to working with them in 2020 as well.
        </p>
        <div class="client-name">
            <p>Jay Ackerman</p>
            <p>CEO</p>
        </div>
        </div>
        </div>
      </carousel>
    </div>
  </div>
</section>
<footer>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <div class="footer-logo">
          <a href="javascript:void(0);">
            <img src="../../assets/images/footer-logo.png" alt="logo" class="img-fluid">
          </a>
          <p class="">Smarter way to Collaborate, Anywhere,</p>
          <p class="">Any Device, Anytime.</p>
        </div>
      </div>
      <div class="col-sm-6 col-md-5 col-lg-6">
        <div class="copyright-text">
          <p>All Rights Reserved Smartdata 2020.</p>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-3">
        <div class="social-media">
          <a href="javascript:void(0);"><i class="fa fa-instagram"></i></a>
          <a href="javascript:void(0);"><i class="fa fa-twitter"></i></a>
          <a href="javascript:void(0);"><i class="fa fa-facebook"></i></a>
        </div>
      </div>
    </div>
  </div>
</footer>