import { Component, Input,OnInit,TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSession,
  MeetingSessionStatusCode,
  DefaultModality,
  VideoTile
} from 'amazon-chime-sdk-js';
import { DataService } from '../services/service';


const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
const deviceController = new DefaultDeviceController(logger);
const roster = {};
const tileMap = {};
const videoElementMap = {};
const hiddenVideos = {};

@Component({
  selector: 'app-meeting-room',
  templateUrl: './meeting-room.component.html',
  styleUrls: ['./meeting-room.component.scss']
})
export class MeetingRoomComponent implements OnInit {

  @ViewChild('exampleModal') editModal : TemplateRef<any>; // Note: TemplateRef

  @Input()
  resultCreateMeeting: Observable<any>;
  resultCreateAttendee:Observable<any>;

  meetingResponse :any ;
  attendeeResponse :any;
  dataService : DataService;
  localTileNumber:Number;
  videoStopped:Boolean = false;
  waitingLabel : boolean = true;


  constructor(private ds:DataService,private route: ActivatedRoute,private modalService: NgbModal,private router:Router) {
    // console.log('Hello world!',logger);
    this.dataService = ds;
  }


  public meeting: string | null = null;
  public name: string | null = null;
  public region: string | null = null;
  // mSession:MeetingSession = null;

  ngOnInit(): void {
    document.getElementById('progress-div').style.display = 'none';
    const titleElement = document.getElementById('inputMeeting') as HTMLInputElement;
    if(this.route.snapshot.queryParams["meetingId"]) {
      titleElement.value = this.route.snapshot.queryParams["meetingId"];
    }
    else {
      titleElement.value = this.makeid(12);
    }
    
  }
  
  configuration : MeetingSessionConfiguration = null;
  meetingSession : DefaultMeetingSession = null;

  async initMedia() {

    this.configuration = new MeetingSessionConfiguration(this.meetingResponse, this.attendeeResponse);
    this.meetingSession = new DefaultMeetingSession(
        this.configuration,
        logger,
        deviceController
      );

    const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
    const audioOutputDevices = await this.meetingSession.audioVideo.listAudioOutputDevices();
    const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();

    // An array of MediaDeviceInfo objects
    audioInputDevices.forEach(mediaDeviceInfo => {
      this.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
    });

    audioOutputDevices.forEach(mediaDeviceInfo => {
      this.log(`Device ID: ${mediaDeviceInfo.deviceId} Speaker: ${mediaDeviceInfo.label}`);
    });
    
    videoInputDevices.forEach(mediaDeviceInfo => {
      this.log(`Device ID: ${mediaDeviceInfo.deviceId} Camera: ${mediaDeviceInfo.label}`);
    });    

    const audioInputDeviceInfo = audioInputDevices[0];
    await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDeviceInfo.deviceId);

    this.meetingSession.audioVideo.chooseVideoInputQuality(960, 540, 15,1400);

    const videoInputDeviceInfo = videoInputDevices[0];
    await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDeviceInfo.deviceId);

    const audioElement = document.getElementById('my-audio-element') as HTMLAudioElement;
    this.meetingSession.audioVideo.bindAudioElement(audioElement);


    const videoElements = [
      document.getElementById('video-0') as HTMLVideoElement,
      document.getElementById('video-1') as HTMLVideoElement,
      document.getElementById('video-2') as HTMLVideoElement,
      document.getElementById('video-3') as HTMLVideoElement,
      document.getElementById('video-4') as HTMLVideoElement,
      document.getElementById('video-5') as HTMLVideoElement,
      document.getElementById('video-6') as HTMLVideoElement,
      document.getElementById('video-7') as HTMLVideoElement,
      document.getElementById('video-8') as HTMLVideoElement,
      document.getElementById('video-9') as HTMLVideoElement,
      document.getElementById('video-10') as HTMLVideoElement,
      document.getElementById('video-11') as HTMLVideoElement,
      document.getElementById('video-12') as HTMLVideoElement,
      document.getElementById('video-13') as HTMLVideoElement,
      document.getElementById('video-14') as HTMLVideoElement,
      document.getElementById('video-15') as HTMLVideoElement
    ];

    // index-tileId pairs
    const indexMap = {};
    
    const acquireVideoElement = tileId => {
      // Return the same video element if already bound.
      // return videoElements[tileId];
      for (let i = 0; i < 16; i += 1) {
        if (indexMap[i] === tileId) {
          // return videoElements[i];
          return i;
        }
      }
      // Return the next available video element.
      for (let i = 0; i < 16; i += 1) {
        if (!indexMap.hasOwnProperty(i)) {
          indexMap[i] = tileId;
          this.log('Acquired tileId:'+tileId + '::::Index='+i);
          // return videoElements[i];
          return i;
        }
      }
      throw new Error('no video element is available');
    };
    
    const releaseVideoElement = tileId => {
      for (let i = 0; i < 16; i += 1) {
        if (indexMap[i] === tileId) {
          delete indexMap[i];
          this.log('released tile::'+tileId +'at i='+i);
          return;
        }
      }
    };
    
    const observer = {
      // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID, a local tile (your video), and a content share.
        
        if (!tileState.boundAttendeeId || tileState.localTile || tileState.isContent) {
          return;
        }

      const val = videoElementMap[tileState.boundAttendeeId]; //get the value from existing dict
      let id;
      if(val == undefined) { // if no entry for this attendee is found in dict; acquire new video element id
        id = acquireVideoElement(tileState.tileId);
        this.log('Acquiring new tile '+ tileState.tileId +' as new attendee added.::::tile-'+id);
      }
      else { // previous video element entry exists;pick up the existing video element id;
        id = val;
        this.log('Using new tile '+ tileState.tileId +' with existing video element id.::::tile-'+id);
      }

       this.meetingSession.audioVideo.bindVideoElement(
        tileState.tileId,
        videoElements[id]
        );
        
      
        const tileElement = document.getElementById(`tile-${id}`) as HTMLDivElement;
        this.log('setting display to block for tile-'+id);
        const nameplateElement = document.getElementById(`nameplate-${id}`) as HTMLDivElement;
        if(tileState.boundExternalUserId.split('#').length>1) {
          nameplateElement.innerText =  tileState.boundExternalUserId.split('#')[1];
        }        
        tileMap[tileState.tileId] = tileState.boundAttendeeId;
        videoElementMap[tileState.boundAttendeeId] = id.toString();
        this.log('**************************')
        this.log(tileMap);
        this.log(videoElementMap);
        this.log('**************************')
        this.layoutTiles();
      },
      videoTileWasRemoved: tileId => {
        this.log('bbbbbbbbbbbbbb')
        this.log(tileMap);
        this.log(videoElementMap);
        this.log('bbbbbbbbbbbbb')

        releaseVideoElement(tileId);
        const atId = tileMap[tileId];
        if(roster[atId]) {
          this.log('Attendee present, video off, hiding old tile element.');
          let temp = videoElementMap[atId];
          delete videoElementMap[atId];
          delete tileMap[tileId];

          // const tileElement = document.getElementById(`tile-${videoElementMap[atId]}`) as HTMLDivElement;
          // this.log('setting display to BLOCK in videoTileWasRemoved for tile-'+id);
          // tileElement.style.display = 'none';
          // this.log('Video element hidden',id);
          const videoElement = document.getElementById(`video-${temp}`) as HTMLVideoElement;
          videoElement.style.display = 'none';
          // videoElement.poster = "../../assets/images/no-video.png";

          // const nameplateElement = document.getElementById(`nameplate-${videoElementMap[atId]}`) as HTMLDivElement;
          // nameplateElement.innerText = "";
          // this.log('setting display to BLOCK for *video* in videoTileWasRemoved for tile-'+id);          

          const id = acquireVideoElement(tileId);
          // console.log('Acquiring new tile ' + tileId +' for camera off attendee.::::tile-',id);

          // const tileElement = document.getElementById(`tile-${id}`) as HTMLDivElement;
          // this.log('setting display to BLOCK in videoTileWasRemoved for tile-'+id);
          // tileElement.style.display = 'block';
          // console.log('Video element hidden',id);
          // const videoElement = document.getElementById(`video-${id}`) as HTMLDivElement;
          // videoElement.style.display = 'block';
          // this.log('setting display to BLOCK for *video* in videoTileWasRemoved for tile-'+id);

          tileMap[tileId] = atId
          videoElementMap[atId] = id.toString();

          this.log('aaaaaaaaaaaaaaaaaa')
          this.log(tileMap);
          this.log(videoElementMap);
          this.log('aaaaaaaaaaaaaaaaaa')
        }
        else {
          
          this.log('Attendee LEFTLEFTLEFT');

          const tileElement = document.getElementById(`tile-${videoElementMap[atId]}`) as HTMLDivElement;
          tileElement.style.display = 'none';

          this.log('setting display to NONE in videoTileWasRemoved LEFT for tile-'+videoElementMap[atId]);
          this.log('Removed tile'+tileId);

          const videoElement = document.getElementById(`video-${videoElementMap[atId]}`) as HTMLDivElement;
          videoElement.style.display = 'none';

          const nameplateElement = document.getElementById(`nameplate-${videoElementMap[atId]}`) as HTMLDivElement;
          nameplateElement.innerText = "";

          delete tileMap[tileId];
          delete videoElementMap[atId];
        }

        this.layoutTiles();
      },
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        if (sessionStatusCode === MeetingSessionStatusCode.AudioCallEnded) {
          this.log('The session has ended');
        } else {
          this.log('Stopped with a session status code: '+ sessionStatusCode);
        }
      },
      audioVideoDidStart: () => {
        this.log('Audio Video Started');
        const localContainer = document.getElementById('local-video-container') as HTMLDivElement;
        localContainer.style.display = "block";
        const nameplateLocal = document.getElementById('nameplate-local') as HTMLDivElement;
        nameplateLocal.innerText = this.name;
        const tileContainer = document.getElementById('tile-container') as HTMLDivElement;
        tileContainer.style.display = "block";        

        document.getElementById('progress-div').style.display = 'none';
        this.layoutTiles();
        
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          this.log('Attempting to reconnect');
        }
        else {
          this.log('Connecting...');
          document.getElementById('progress-div').style.display = 'block';
          // this.modalService.open(this.editModal,{ centered: true});
        }
      }
      
    };
    
    this.meetingSession.audioVideo.addObserver(observer);
    this.localTileNumber=this.meetingSession.audioVideo.startLocalVideoTile();
    this.meetingSession.audioVideo.start();

    const ele = document.getElementById('meeting-link') as HTMLDivElement;
    // ele.innerText = 'MeetingId=' + this.meeting;
    ele.style.display = "block";
    // const t = document.getElementById('text-meeting-url') as HTMLInputElement;
    // t.value = "https://staging.stagingsdei.com/meeting-room?meetingId=" + this.meeting;
    

    const localVideo = document.getElementById('my-local-video') as HTMLVideoElement;
    this.meetingSession.audioVideo.getLocalVideoTile().bindVideoElement(localVideo);

    /// new code starts here
    /// observer to keep attendees list updated on joining and leaving events.
    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId, present) => {
        if (!present) {
          delete roster[presentAttendeeId];
          return;
        }
    
        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          (attendeeId, volume, muted, signalStrength) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base();
            if (baseAttendeeId !== attendeeId) {
              // Optional: Do not include the content attendee (attendee-id#content) in the roster.
              // See the "Screen and content share" section for details.
              return;
            }
    
            if (roster.hasOwnProperty(attendeeId)) {
              // A null value for any field means that it has not changed.
              roster[attendeeId].volume = volume; // a fraction between 0 and 1
              roster[attendeeId].muted = muted; // A booolean
              roster[attendeeId].signalStrength = signalStrength; // 0 (no signal), 0.5 (weak), 1 (strong)
            } else {
              // Add an attendee.
              // Optional: You can fetch more data, such as attendee name,
              // from your server application and set them here.
              roster[attendeeId] = {
                attendeeId,
                volume,
                muted,
                signalStrength
              };
            }
          }
        );
      }
    );
    /// new code ends here

  }

  layoutTiles() {
    let tilesCount = 0;    
    // const tilesArray = this.meetingSession.audioVideo.getAllRemoteVideoTiles();
    // tilesCount = tilesArray.length;
    let tilesArray = [];
    for(let i in videoElementMap) {
      // console.log("LAYOUTTILES:::",i)
      tilesArray.push(videoElementMap[i])
      tilesCount++;
    }


    let cName = "video-item col-sm-4";
    if(tilesCount==1) {
      cName = "video-item col-sm-10";
    }
    else if(tilesCount==2) {
      cName = "video-item col-sm-6";
    }
    else if(tilesCount>=3) {
      cName = "video-item col-sm-4";
    }
    
    for (let c=0;c<tilesArray.length;c++) {
      // const atId = tileMap[tilesArray[c].id()]
      //const atId = tileMap[tilesArray[c]]
      // const tileElement = document.getElementById(`tile-${videoElementMap[tilesArray[c].id()]}`) as HTMLDivElement;
      const tileElement = document.getElementById(`tile-${tilesArray[c]}`) as HTMLDivElement;
      this.log(`Setting class ${cName} for:---tile-${tilesArray[c]}`);
      
      tileElement.className = cName;
      tileElement.style.display = 'block';

      const videoElement = document.getElementById(`video-${tilesArray[c]}`) as HTMLVideoElement;
      videoElement.style.display = 'block';
      // videoElement.poster = "";

    }

    if(tilesCount==0) {
      const waitingDiv = document.getElementById('div-waiting') as HTMLDivElement;
      waitingDiv.style.display = 'block';
    }
    else {
      const waitingDiv = document.getElementById('div-waiting') as HTMLDivElement;
      waitingDiv.style.display = 'none';
    }

    return;
    
  }

  onStartMeeting() {
    

    const titleElement = document.getElementById('inputMeeting') as HTMLInputElement;
    const nameElement = document.getElementById('inputName') as HTMLInputElement;
    this.meeting = titleElement.value;
    this.name = nameElement.value;

    const validationDiv = document.getElementById('validation-error') as HTMLDivElement;
    if(nameElement.value.trim()=="") {
      validationDiv.style.display = 'block';
      return;
    }
    else {
      validationDiv.style.display = 'none';
    }
    document.getElementById('progress-div').style.display = 'block';

    this.log('Start meeting...'+this.meeting);
    document.getElementById('form-container').className += ' disabled';

    // call create-meeting API
    this.resultCreateMeeting = this.dataService.createMeeting(this.meeting);
    this.resultCreateMeeting.subscribe(resp => {
      this.meetingResponse = resp;
      this.log('Meeting response='+this.meetingResponse);
      this.log('MeetingId='+this.meetingResponse.Meeting.MeetingId);

      // call create-attendee API
      this.resultCreateAttendee = this.dataService.createAttendee(this.meetingResponse.Meeting.MeetingId,this.name);
      this.resultCreateAttendee.subscribe(resp2 => {
        this.attendeeResponse = resp2;
        this.log('Attendee response='+this.attendeeResponse);
        this.log('AttendeeId='+this.attendeeResponse.Attendee.AttendeeId);


        document.getElementById('meeting-room-form').style.visibility='hidden';
        document.getElementById('meeting-room-form').style.display='none';
        document.getElementById('form-container').className = '';
        // based on above API calls, initialize meeting
        this.initMedia();
      })
    })
  }

  onAudioButton() {
    const muted = this.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
    const imgAudio = document.getElementById('img-audio') as HTMLImageElement;
    if (muted) {
      this.log('You are muted');
      imgAudio.src = "../../assets/images/mic.svg"
      // Mute
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      this.log('Other attendees can hear your audio');
      imgAudio.src = "../../assets/images/mic-muted.svg"
      this.meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
  }

  async onVideoButton() {
    const imgVideo = document.getElementById('img-video') as HTMLImageElement;
    if (!this.videoStopped) {
      this.log('Other attendees can\'t view your video');
      // Mute
      this.meetingSession.audioVideo.stopLocalVideoTile();
      imgVideo.src = "../../assets/images/video-muted.svg"
      this.meetingSession.audioVideo.removeLocalVideoTile();
    } else {
      this.log('Other attendees can view your video');

      const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
      const videoInputDeviceInfo = videoInputDevices[0];
      await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDeviceInfo.deviceId);

      // UnMute
      this.meetingSession.audioVideo.startLocalVideoTile();
      imgVideo.src = "../../assets/images/video.svg"

      const localVideo = document.getElementById('my-local-video') as HTMLVideoElement;
      this.meetingSession.audioVideo.getLocalVideoTile().bindVideoElement(localVideo);      
    }
    this.videoStopped = !this.videoStopped;
  }

  onHangupButton() {
    this.log('Meeting ended for all.');
    this.meetingSession.audioVideo.stop();
    this.router.navigateByUrl('/');
    
  }

  onCopyMeetingLink() {
  
    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    };

    copyToClipboard("https://allsmartvideo.net/meeting-room?meetingId=" + this.meeting);
    alert('Meeting link copied to clipboard.');
  }



  log(message:any) {
    // console.log(message);
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }


}
