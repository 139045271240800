
<div id="form-container" class="ddd">
  <div class="container">
<div id="meeting-room-form" class="flow text-center col-md-4 offset-md-4 col-sm-6 offset-sm-3">
    <div class="text-muted" style="position:fixed;right:3px;bottom:3px" id="sdk-version"></div>
    
      <form id="form-authenticate" >
        <h1 class="h3 mb-3 font-weight-normal">Join a meeting</h1>
        <div class="row mt-3">
          <label for="inputMeeting" class="sr-only">Meeting Title</label>
          <input type="text" id="inputMeeting" class="form-control" placeholder="Meeting Title" required readonly>
        </div>
        <div class="row mt-3">
          <label for="inputName" class="sr-only">Your Name</label>
          <input type="name" id="inputName" class="form-control" placeholder="Your Name" required autofocus>
          
        </div>
        <div id="validation-error" style="display: none;" class="row mt-3">
          <div id="labelValidation" class="text-danger text-center">
            Please enter your name.
          </div>
        </div>

        <div class="row mt-3">
          <button id="authenticate" class="btn btn-lg btn-primary btn-block" (click)="onStartMeeting()"  type="button">Start Meeting</button>
        </div>
        <div class="row mt-3">
          <button id="showProgress" data-toggle="modal" data-target="#exampleModal"  type="button" style="display: none;"></button>
        </div>        
      </form>
    
  </div>
</div>
  
  

  <audio id='my-audio-element'></audio>
  <div id="div-waiting" style="display: none;">
  <div id="labelWaiting" class="d-flex col-set  justify-content-center"><span></span>Waiting for others to join</div>
  </div>
  <div id="tile-container" class="" style="display: none;">
      
      <div id="tile-area" class="row col-set">
        <div id="tile-0" class="video-item col-sm-4" style="display: none;">
          <video id="video-0" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-0" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-1"  class="video-item  col-sm-4" style="display: none;">
          <video id="video-1" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-1" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-2"  class="video-item col-sm-4" style="display: none;">
          <video id="video-2" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-2" align="center" class="text"></div>
            </div>
        </div>
        
        <div id="tile-3"  class="video-item col-sm-4" style="display: none;">
          <video id="video-3" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-3" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-4"  class="video-item col-sm-4" style="display: none;">
          <video id="video-4" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-4" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-5"  class="video-item col-sm-4" style="display: none;">
          <video id="video-5" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-5" align="center" class="text"></div>
            </div>
        </div>
        
        <div id="tile-6" class="video-item col-sm-4"  style="display: none;">
          <video id="video-6" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-6" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-7"  class="video-item col-sm-4" style="display: none;">
          <video id="video-7" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-7" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-8"  class="video-item col-sm-4" style="display: none;">
          <video id="video-8" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-8" align="center" class="text"></div>
            </div>
        </div>
        
        <div id="tile-9"  class="video-item col-sm-4" style="display: none;">
          <video id="video-9" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-9" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-10" class="video-item col-sm-4"  style="display: none;">
          <video id="video-10" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-10" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-11"  class="video-item col-sm-4" style="display: none;">
          <video id="video-11" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-11" align="center" class="text"></div>
            </div>
        </div>
        
        <div id="tile-12" class="video-item col-sm-4"  style="display: none;">
          <video id="video-12" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-12" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-13"  class="video-item col-sm-4" style="display: none;">
          <video id="video-13" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-13" align="center" class="text"></div>
            </div>
        </div>
        <div id="tile-14"  class="video-item col-sm-4" style="display: none;">
          <video id="video-14" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-14" align="center" class="text"></div>
            </div>
        </div>
        
        <div id="tile-15"  class="video-item col-sm-4" style="display: none;">
          <video id="video-15" class="col"></video>
          <div class="col name-box">
            <div id="nameplate-15" align="center" class="text"></div>
            </div>
        </div>
      </div>
  </div>
  
 
  
  <div id="local-video-container" align="center" style="display: none;">
    <!--<div id="meeting-link" class="text"></div>-->
    
    <div id="meeting-link" class="meetingid" (click)="onCopyMeetingLink()">
      <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.558" height="13.73" viewBox="0 0 11.558 13.73">
      <path id="copy_3_" data-name="copy(3)" d="M7.267,13.73H2.145A2.148,2.148,0,0,1,0,11.585V4.317A2.148,2.148,0,0,1,2.145,2.172H7.267A2.148,2.148,0,0,1,9.413,4.317v7.267A2.148,2.148,0,0,1,7.267,13.73ZM2.145,3.245A1.074,1.074,0,0,0,1.073,4.317v7.267a1.074,1.074,0,0,0,1.073,1.073H7.267A1.074,1.074,0,0,0,8.34,11.585V4.317A1.074,1.074,0,0,0,7.267,3.245Zm9.413,7v-8.1A2.148,2.148,0,0,0,9.413,0H3.459a.536.536,0,0,0,0,1.073H9.413a1.074,1.074,0,0,1,1.073,1.073v8.1a.536.536,0,0,0,1.073,0Zm0,0" fill="#6b6b6b"/>
    </svg>
  </span>
    Copy link</div>
    <video id="my-local-video" class="col local-video">
    </video>
    
    <div class="col button-box">
      <div id="nameplate-local" align="center" class="text-muted"></div>
          <button id="mute-camera" (click)="onVideoButton()" class="button-class btn btn-border">
            <img id="img-video" height="20px" width="20px" src="../../assets/images/video.svg" alt=""/>
        </button>
        <button id="mute-audio" (click)="onAudioButton()" class="button-class btn btn-border">
            <img id="img-audio" height="20px" width="20px" src="../../assets/images/mic.svg" alt=""/>
        </button>
        <button id="hang-up" (click)="onHangupButton()" class="button-class btn btn-border">
          <img id="img-hangup" height="24px" width="24px" src="../../assets/images/end-call.png" alt=""/>
      </button>        
      <!-- <button id="settings" class="button-class btn btn-border">
        <svg width="20px" height="20px" viewBox="0 0 16 16" class="button-class bi bi-gear" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"/>
          <path fill-rule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"/>
        </svg>
  
      </button> -->
      </div>
  </div>

<div class="loading"  style="display:block;" id="progress-div">
  <img src="../../assets/images/loading_anim.gif">
  <h5  class="modal-title" >Connecting, Please wait...</h5>
</div>

<!-- Modal -->
<ng-template #exampleModalDialog>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
</ng-template>

</div>